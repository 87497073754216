<template>
  <b-card no-body>
    <b-row class="px-2">
      <!-- Per Page -->
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center flex-wrap justify-content-start mb-1 mb-md-0 mt-1"
      >
        <label>Entrées</label>

        <v-select
          v-model="state.perPage"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="perPageOptions"
          :clearable="false"
          class="per-page-selector d-inline-block ml-50 mr-1"
        />

        <b-button variant="primary" :to="{ name: 'gsMySupplyStore' }">
          <feather-icon icon="ShopIcon" class="mx-auto" />
          M'approvisionner
        </b-button>
      </b-col>

      <!-- Search -->

      <b-col cols="12" md="6" class="mt-1">
        <div class="d-flex align-items-center justify-content-end">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="state.filtre"
              class="d-inline-block mr-1"
              placeholder="Rechercher par : code, type, prix, date..."
            />
          </b-input-group>
        </div>
      </b-col>
    </b-row>

    <q-loader-table
      :success="state.success"
      :empty="state.empty"
      :warring="state.warring"
    />

    <b-table
      hover
      responsive
      primary-key="id"
      :per-page="state.perPage"
      :current-page="state.currentPage"
      :items="supplyThreshold"
      :fields="tableColumns"
      :filter="state.filter"
      show-empty
      empty-text="Aucun article enregistré"
      class="bg-white text-left"
      v-if="state.success === true"
    >
      <template #cell(code_facture)="data">
        <div>
          <span> {{ data.item.code_facture }} </span>
        </div>
      </template>

      <template #cell(prix_vente)="data">
        <div>
          <span> {{ formatter(data.item.prix_vente) }} </span>
        </div>
      </template>

      <template #cell(actions)="data">
        <div class="text-nowrap py-1">
          <feather-icon
            v-b-modal.e-edit-seller
            @click="editSellerData(data.item)"
            icon="Edit3Icon"
            size="16"
            class="cursor-pointer"
          />
        </div>
      </template>
    </b-table>

    <!-- Les boutons de pagination -->

    <div class="mx-2 mb-2 pb-1">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"></span>
        </b-col>

        <!-- Pagination -->

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="state.currentPage"
            :total-rows="supplyThreshold.length"
            :per-page="state.perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>

            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>

    {{ __OnRealTime }}
  </b-card>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BModal,
  VBModal,
  BForm,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import axios from "axios";
import { onMounted, reactive, ref, computed } from "@vue/composition-api";
import QLoaderTable from "@/components/__partials/loaders/qLoaderTable.vue";
import Article from "../dashboard/article/article.vue";
import vSelect from "vue-select";
import URL from "../request";
import moment from "moment";
import qDeviseUtils from "@/utils/qDeviseUtils";
import { qDecrypted } from "@/services/qeCrypt";

export default {
  components: {
    BInputGroupPrepend,
    BRow,
    BImg,
    BCol,
    BLink,
    BModal,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    VBModal,
    QLoaderTable,
    vSelect,
    Article,
  },
  setup(props, { root }) {
    const state = reactive({
      perPage: 30,
      currentPage: 1,
      filter: "",
      success: false,
      empty: false,
      warring: false,
      loading: false,
    });
    const supplyThreshold = ref([]);
    const perPageOptions = ["30"];
    const GIVE = ref(false);
    const tableColumns = reactive([
      {
        key: "code",
        label: "code",
      },
      {
        key: "prix_vente",
        label: "Prix de vente",
      },
      {
        key: "seuil",
        label: "Seuil",
      },
      {
        key: "qte_restante",
        label: "Quantité Restante",
      },
      {
        key: "prix_achat",
        label: "Prix Achat",
      },
      {
        key: "created_at",
        label: "Date de création",
      },
      {
        key: "actions",
        label: "Actions",
      },
    ]);

    onMounted(async () => {
      await listSupplyThreshold();
    });
    const __OnRealTime = computed(() => {
      if (GIVE.value === true) {
        supplyThreshold.value.length === 0
          ? ((state.empty = true), (state.success = false))
          : ((state.success = true), (state.empty = false));
      }
    });

    const listSupplyThreshold = async () => {
      try {
        const { data } = await axios.get(URL.INVENTORY_LIST);
        const _supplyThreshold = [];
        if (data.seuil_app) {
          console.log(data.seuil_app);
          GIVE.value = true;
          for (let i = 0; i < data.seuil_app.length; i++) {
            const item = data.seuil_app[i];
            item.created_at = moment(item.created_at).format("DD-MM-YYYY");
          }

          supplyThreshold.value = data.seuil_app;
        }
      } catch (error) {
        state.success = false;
        state.warring = true;
        console.log(error);
      }
    };

    const formatter = (amount) => {
      const dataToDecrypt = localStorage.getItem("entreprise_info");
      return qDeviseUtils.formatter(qDecrypted(dataToDecrypt), amount);
    };

    return {
      state,
      supplyThreshold,
      tableColumns,
      perPageOptions,
      __OnRealTime,
      formatter,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
